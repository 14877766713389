<template>
	<div class="md-layout text-center">
		<div class="md-layout-item md-size-33 md-medium-size-50 md-small-size-70 md-xsmall-size-100">
			<login-card header-color="light-blue" class="card-login">
				<div slot="title" class="logo-container">
					<h4 class="title">Painel MobRadio</h4>
					<img src="../../../assets/logo-m-fundo-transparente-m-branco.png">
				</div>
				<md-field class="md-form-group" slot="inputs">
					<md-icon>face</md-icon>
					<label>Nome de usuário ou email</label>
					<md-input v-model="email" ref="email-input"></md-input>
				</md-field>

				<md-field class="md-form-group" slot="inputs">
					<md-icon>lock_outline</md-icon>
					<label>Senha</label>
					<md-input type="password" ref="password-input" v-model="password" @keyup.enter="enterClicked"></md-input>
				</md-field>

				<md-button ref="button-login" slot="footer" class="md-simple light-blue md-lg" @click="doLogin()"
					:disabled="!formValidate">Entrar</md-button>
			</login-card>
			<p>{{ getVersion }}v</p>
		</div>
	</div>
</template>
<script>
import models from "../../../routes/models.js";
import package_json from "../../../../package.json";
import { LoginCard } from "@/components";
import Swal from "sweetalert2";
import jwt from "jsonwebtoken";
import jwt_decode from "jwt-decode";
import mixpanelAdapter from "../../../utils/events/mixpanelAdapter";

export default {
	name: "Login",
	components: {
		LoginCard
	},
	data() {
		return {
			email: "",
			password: "",
			pass1: "",
			pass2: "",
			formValidate: false,
			ipAddress: ""
		};
	},
	methods: {
		sendMixPanelEvent(eventString){
			mixpanelAdapter.track(eventString, {
				screen_name: this.$route.name
			})
		},
		doLogin() {
			let vm = this;
			Swal.fire({
				onOpen: function () {
					Swal.fire({
						title: "Aguarde",
						html: "Estamos reunindo suas informações antes de prosseguir...",
						type: "warning",
						onOpen: () => {
							Swal.showLoading();
						}
					});

					vm.validateLogin().then(async (userLogged) => {
						if (!userLogged) {
							return
						}

						const passwordIsDefault = vm.checkIfPasswordIsDefault(userLogged.password)
						if (passwordIsDefault) {
							const confirmChangePassword = await Swal.fire({
								title: "Dica de segurança",
								text: "Sua senha atual é a senha padrão. Recomendamos que você crie uma nova senha.",
								type: "warning",
								showCancelButton: true,
								confirmButtonColor: "#3085d6",
								cancelButtonColor: "#d33",
								confirmButtonText: "Alterar senha",
								cancelButtonText: "Depois"
							}).then(result=>{
								vm.sendMixPanelEvent("login_change_default_password_popup_opened")
								return result.value
							})

							if (confirmChangePassword) {
								await vm.changeDefaultPassword(userLogged.id)
								await vm.sendMixPanelEvent("login_change_default_password_sucessful")
							}else{
								await vm.sendMixPanelEvent("login_change_default_password_canceled")
							}
						}

						const currentUserIsTheLastLogged = vm.checkIfIsLastUserLogged(userLogged.id)
						if (currentUserIsTheLastLogged) {
							vm.sendMixPanelEvent("login_successful")
							vm.$router.push({ path: "dashboard" });
							return;
						}

						try{
							localStorage.clear()
							vm.setUserTokenInLocalInStorage(userLogged.token)

							const firstRadio = await vm.getFirstRadio(userLogged.id)
							vm.setRadioDataInLocalStorage(firstRadio)
							
							let radioServices = []
							if(!firstRadio.is_parent){
								radioServices = await vm.getServicesFromRadio(firstRadio.id)
							}

							vm.setRadioServicesInLocalStorage(radioServices)

							vm.sendMixPanelEvent("login_successful")
							vm.$router.push({ path: "dashboard" });
						}catch(error){
							console.log(error)
							Swal(
								"Atenção",
								"Não encontramos nenhuma rádio cadastrada pra esse usuário e não foi possível realizar login. Por favor entre em contato com o suporte",
								"warning"
							);
							localStorage.clear()
							return
						}
					})
				}
			});
		},

		async getAccessLogs(id) {
			return await models().get(`users/getAccessLogs?userId=${id}`);
		},
		async makeLoginRequest() {
			const credentials = {
				email: this.email,
				password: this.password
			};

			const requestResponse = await models()
				.post("users/login", credentials)
				.then(resp => {
					return resp;
				})
				.catch(e => {
					if (e.response) {
						return e.response;
					}
				});
			return requestResponse
		},

		async validateLogin() {
			const requestResponse = await this.makeLoginRequest()
			if (requestResponse.status === 401) {
				await Swal(
					"Credenciais inválidas",
					"Usuário e/ou senha incorreto(s). Por favor, tente novamente.",
					"error"
				);

				return
			}

			if (requestResponse.status === 403) {
				await Swal(
					"Usuário inativo",
					"Este usuário está inativo e não pode fazer login.",
					"error"
				);

				return
			}

			if (!requestResponse.data.user) {
				await Swal(
					"Erro",
					"Houve um erro ao fazer o login. Tente novamente e, caso persista, entre em contato com o suporte.",
					"error"
				);
				return
			}
			const userToken = requestResponse.data.token
			const user = { ...requestResponse.data.user[0], token: userToken }

			return user
		},

		checkIfPasswordIsDefault(password) {
			const defaultPassword = "305dc240d5c996539209c25abaae85a23139e06f";
			if (password === defaultPassword) {
				return true;
			}
			return false;
		},

		async changeDefaultPassword(userId) {
			await Swal.fire({
				title: "Digite a nova senha",
				html: `<input type="password" id="pass1" class="swal2-input">
               <label>Confirme sua senha: </label><input type="password" id="pass2" class="swal2-input">`,
				showCancelButton: true,
				cancelButtonColor: "#d33",
				cancelButtonText: "Cancelar",
				confirmButtonText: "Alterar",
				showLoaderOnConfirm: true,
				allowOutsideClick: false,
				preConfirm: () => {
					return new Promise(function (resolve) {
						const val_1 = document.getElementById("pass1").value;
						const val_2 = document.getElementById("pass2").value;
						if (val_1.length < 6) {
							Swal.showValidationError(
								"A senha deve conter no minimo 6 digitos."
							);
						}
						if (val_1 != val_2) {
							Swal.showValidationError("As senhas digitadas não conferem.");
						} else {
							const dataForm = {
								password: val_2,
								id: userId
							};
							models()
								.put("users/updatePassword", dataForm)
								.then(async () => {
									await Swal(
										"Pronto",
										"Sua senha foi alterada com sucesso!",
										"success"
									);
								})
								.catch(e => {
									console.log(e);
								});
							resolve();
						}
						resolve();
					});
				}
			});
		},

		checkIfIsLastUserLogged(id) {
			if (localStorage.getItem("SET_APP")) {
				const lastUserLoggedID = jwt_decode(
					localStorage.getItem("SET_APP")
				).user[0].id;
				const currentUserLoggedID = id;
				if (currentUserLoggedID === lastUserLoggedID) {
					return true;
				}
			}
			return false
		},

		setUserTokenInLocalInStorage(token){
			localStorage.setItem("SET_APP", token);
		},
		setRadioDataInLocalStorage(radio){
			const token = jwt.sign({ radio }, "12345", {
				expiresIn: 600
			});

			localStorage.setItem(
				"APP_01",
				JSON.stringify(token).replaceAll('"', "")
			);
		},

		async setRadioServicesInLocalStorage(services) {
			const token = jwt.sign({ services }, "12345", {
				expiresIn: 600
			});
			localStorage.setItem(
				"APP_02",
				JSON.stringify(token).replaceAll('"', "")
			);
		},

		async getFirstRadio(userId) {
			const allRadiosFromUser = (await models().get(`users/getRadiosByUserId?userId=${userId}`)).data;

			const radio = {
				id: allRadiosFromUser[0].id,
				name: allRadiosFromUser[0].name,
				key: allRadiosFromUser[0].key,
				server_key: allRadiosFromUser[0].server_key,
				logo: allRadiosFromUser[0].image_logo_url,
				cloud_name: allRadiosFromUser[0].cloudinary_cloud_name,
				cloud_key: allRadiosFromUser[0].cloudinary_api_key,
				cloud_secret: allRadiosFromUser[0].cloudinary_api_secret,
				master_key: allRadiosFromUser[0].key,
				is_parent: allRadiosFromUser[0].is_parent
			}
			return radio;
		},

		async getServicesFromRadio(id) {
			const radioServices = await models()
				.get(`services/getAllRadioServices/${id}`)
				.then(resp => {
					return resp.data.map(item=>{
						return {
							service_name: item.service_name,
							service_url: item.service_url,
							vue_icon: item.vue_icon,
							label: item.label,
							menu_group: item.menu_group,
							description: item.description,
							image: item.image,
							has_permission: item.has_permission
						}
					})
				});

			return radioServices
		},

		enterClicked() {
			this.doLogin();
		},
		arrayBufferToBase64(ab) {
			let dView = new Uint8Array(ab); //Get a byte view
			let arr = Array.prototype.slice.call(dView); //Create a normal array
			let arr1 = arr.map(function (item) {
				return String.fromCharCode(item); //Convert
			});
			return window.btoa(arr1.join("")); //Form a string
		},
		convertDateToUTCString(arg) {
			const date = new Date(arg);
			const aux = date.toUTCString();
			return aux;
		},
		convertDateToLocalString(date) {
			let newDate = new Date(date);
			let day = newDate.getDate();
			let month = newDate.getMonth() + 1;
			let fullYear = newDate.getFullYear();
			let hour = newDate.getHours();
			let minutes = newDate.getMinutes();

			if (day < 10) {
				day = "0" + day;
			}
			if (month < 10) {
				month = "0" + month;
			}
			if (hour < 10) {
				hour = "0" + hour;
			}
			if (minutes < 10) {
				minutes = "0" + minutes;
			}

			return day + "/" + month + "/" + fullYear + " " + hour + ":" + minutes;
		},

		radioUsersAccessLog() {
			const user = jwt_decode(localStorage.getItem("SET_APP")).user;
			const radio_change_log_form = {
				user_id: user.id,
				user_username: user.username,
				user_first_name: user.name,
				user_lastname: user.lastname,
				user_email: user.email,
				user_agent: navigator.userAgent
			};
			models().post("radios/radioUsersAccessLog", radio_change_log_form);
		}
	},
	computed: {
		getVersion() {
			return package_json.version;
		}
	},
	watch: {
		email() {
			if (this.email == "" || this.password == "") {
				this.formValidate = false;
			} else {
				this.formValidate = true;
			}
		},
		password() {
			if (this.email == "" || this.password == "") {
				this.formValidate = false;
			} else {
				this.formValidate = true;
			}
		}
	}
};
</script>

<style>
.card-login .social-line {
	padding: 0 0 !important;
}

.card-login .md-card-header {
	padding: 16px !important;
}


</style>

<style scoped>
.title {
	font-weight: 500;
}

.logo-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.logo-container img {
	width: 25px;
	height: 25px;
}

.logo-container h4 {
	height: 30px;
	line-height: 30px;
	font-size: 150%;
	font-weight: 500 !important;
	margin: 0 !important;
}

</style>